var JSBoot = JSBoot || {};

(function ($, Drupal, site) {
  // This needs to only run once since we're keeping track of async libs.
  if (Drupal.behaviors.productVideo) {
    return;
  }

  Drupal.behaviors.productVideo = {
    mediaLibrarySubjectHash: new JSBoot.SubjectHash(),
    currentObserver: null,
    attach: function (context, settings) {
      // Empty. We delegate to document listeners below.
    }
  };

  // Utils.
  var productVideoBehavior = Drupal.behaviors.productVideo;
  var getMediaSubject = function (provider) {
    var subject = productVideoBehavior.mediaLibrarySubjectHash.get(provider);

    return subject;
  };
  /*
   * Don't want to deal with multiple clicks waiting on async lib.
   *
   * Seems unlikely since youtube api js is small but do it anyways.
   */
  var unsetCurrentObserver = function () {
    var currentObserver = productVideoBehavior.currentObserver;

    if (currentObserver) {
      currentObserver.unsubscribe();
    }
    productVideoBehavior.currentObserver = null;
  };
  // Prime html5 as it is not async.
  var html5Subject = getMediaSubject('html5');

  html5Subject.next('loaded');

  /*
   * Translate event handler into BehaviorSubject idiom.
   */
  $(document).on('youtubeIframeAPI.loaded', function () {
    var subject = getMediaSubject('youtube');

    subject.next('loaded');
  });

  $(document).on('click', '.js-product-video .js-play', function (event) {
    event.preventDefault();

    // clear out any previous observer.
    unsetCurrentObserver();

    var $play = $(this);
    var $module = $play.closest('.js-product-video');
    var provider = $module.data('video-provider');
    var isYoutube = provider === 'youtube';
    var isVimeo = provider === 'vimeo';
    var isZentrick = provider === 'zentrick';
    var isHTML5 = provider === 'html5';
    var opts = {
      provider: provider,
      openOverlay: $play.hasClass('js-play--overlay')
    };

    if (!!isYoutube) {
      opts.youTubeId = $module.data('youtube-id');
    } else if (!!isVimeo) {
      opts.vimeoId = $module.data('vimeo-id');
    } else if (!!isZentrick) {
      opts.zentrickId = $module.data('zentrick-id');
    } else if (!!isHTML5) {
      opts.content = $module.find('video').wrap('<div />').parent().html();
    }

    var subject = getMediaSubject(provider);
    var observer = subject.subscribe(function () {
      // disconnect this observer. This technically disconnects all observers
      // but we're locked to only one anyways.
      // Really need to port the rxJS first() method.
      subject.unsubscribe();
      site.videos.open(opts);
    });

    productVideoBehavior.currentObserver = observer;

    return false;
  });
})(jQuery, Drupal, window.site || {});
